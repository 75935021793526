import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroAboutUs from '../components/HeroAboutUs';
import SectionAboutUs from '../components/SectionAboutUs';
import SectionTeam from '../components/SectionTeam';
import SectionArticles from '../components/SectionArticles';

const Sobre = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: {
        about_us_hero: hero,
        about_us_description: description,
        about_us_team: team,
        about_us_articles: articles,
      },
    },
  } = data;

  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={page.polylang_translations}
      useTranslationsPath={true}
    >
      <SEO {...page} pageLang={page.polylang_current_lang} />
      <HeroAboutUs {...hero} />
      <SectionAboutUs {...description} />
      <SectionTeam {...team} />
      <SectionArticles {...articles} negativeOffset />
    </Layout>
  );
};

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        about_us_hero {
          title
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        about_us_description {
          description
          title
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        about_us_team {
          title
          members {
            description
            job
            name
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            object_position_x
            object_position_y
          }
          advisors {
            description
            job
            name
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            object_position_x
            object_position_y
          }
        }
        about_us_articles {
          posts {
            excerpt
            link
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Sobre;
