import React, { useState, useRef } from 'react';
import P from 'prop-types';
import '../slick.css';
import Style from './style.module.css';
import Slider from 'react-slick';

// Components
import TeamMemberCard from './TeamMemberCard';
import Img from '@components/ImageWrapper';

// Utils
import { translate as t } from '../../utils/translate';

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const DetailedMember = ({ member, setTeamMember, setAdvisor, sectionRef }) => {
  const TEXTS = {
    BACK_BUTTON: t('back'),
  };

  if (!member) return React.Fragment;

  const getAllLineBreaks = /(?:\r\n|\r|\n)/g;
  const description = member.description.replace(getAllLineBreaks, '<br>');
  return (
    <div className="container">
      <div
        onClick={() => {
          scrollToRef(sectionRef);
          setTeamMember(null);
          setAdvisor(null);
        }}
        className={Style.backButton}
      >
        {TEXTS.BACK_BUTTON}
      </div>
      <div className={Style.detailedMember}>
        <div className={Style.imageContainer}>
          <Img
            src={member}
            style={{ width: '100%', height: '100%' }}
            className={Style.detailedMemberImage}
          />
          <div className={Style.imageOverlay} />
        </div>
        <div className={Style.detailedMemberInfo}>
          <p className={Style.detailedMemberName}>{member.name}</p>
          <p className={Style.detailedMemberJob}>{member.job}</p>
          <p
            className={Style.detailedMemberDescription}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

DetailedMember.propTypes = {
  member: P.any,
  setTeamMember: P.func,
  setAdvisor: P.func,
  sectionRef: P.object,
};

const SectionTeam = ({ title, members, advisors }) => {
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [clientXonMouseDown, setClientXonMouseDown] = useState(0);
  const [clientYonMouseDown, setClientYonMouseDown] = useState(0);

  const TEXTS = {
    TEAM: t('team'),
    ADVISERS: t('advisers'),
  };

  const sectionRef = useRef(null);
  const detailedMemberRef = useRef(null);

  const handleOnMouseDown = e => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault(); // stops weird link dragging effect
  };

  const handleOnClick = (e, ref, index, type) => {
    e.stopPropagation();

    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    } else {
      scrollToRef(ref);

      if (type === 'advisor') {
        setSelectedAdvisor(index);
      } else {
        setSelectedTeamMember(index);
      }
    }
  };

  const carouselSettings = {
    infinite: false,
    variableWidth: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const teamMemberList = members.map((member, index) => {
    return (
      <TeamMemberCard
        onClick={e => {
          handleOnClick(e, detailedMemberRef, index);
        }}
        onMouseDown={e => handleOnMouseDown(e)}
        selected={selectedTeamMember === index}
        key={'member' + index}
        name={member.name}
        job={member.job}
        image={member.image}
        imagePosition={[member.object_position_x, member.object_position_y]}
      />
    );
  });

  const advisorsList = advisors.map((advisor, index) => {
    return (
      <TeamMemberCard
        onClick={e => {
          handleOnClick(e, detailedMemberRef, index, 'advisor');
        }}
        onMouseDown={e => handleOnMouseDown(e)}
        selected={selectedAdvisor === index}
        key={'advisor' + index}
        name={advisor.name}
        job={advisor.job}
        image={advisor.image}
        imagePosition={[advisor.object_position_x, advisor.object_position_y]}
      />
    );
  });

  return (
    <section ref={sectionRef} className={Style.sectionTeam}>
      <div className={`${Style.sectionHeader} container`}>
        <div className={Style.sectionTitle}>{title}</div>
        <div className={Style.tabs}>
          <span
            onClick={() => setActiveTab(0)}
            className={Style.tab + (0 === activeTab ? ' ' + Style.active : '')}
          >
            {TEXTS.TEAM}
          </span>
          <span
            onClick={() => setActiveTab(1)}
            className={Style.tab + (1 === activeTab ? ' ' + Style.active : '')}
          >
            {TEXTS.ADVISERS}
          </span>
        </div>
      </div>
      <div className={Style.sliderContainer}>
        <Slider className={Style.slider} {...carouselSettings}>
          {activeTab === 0
            ? teamMemberList
            : activeTab === 1
            ? advisorsList
            : ''}
        </Slider>
        <div className={Style.sliderOverlay} />
      </div>
      <div className={Style.detailedMemberContainer}>
        <span className={Style.detailedMemberAnchor} ref={detailedMemberRef} />
        <DetailedMember
          sectionRef={sectionRef}
          setTeamMember={setSelectedTeamMember}
          setAdvisor={setSelectedAdvisor}
          member={
            activeTab === 0
              ? members[selectedTeamMember]
              : activeTab === 1
              ? advisors[selectedAdvisor]
              : ''
          }
        />
      </div>
    </section>
  );
};

SectionTeam.propTypes = {
  title: P.string,
  members: P.array,
  advisors: P.array,
};

export default SectionTeam;
