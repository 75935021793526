import React from 'react';
import P from 'prop-types';
import Style from './TeamMemberCard.module.css';

// Components
import Img from '@components/ImageWrapper';

// Utils
import { translate as t } from '@utils/translate';

const TeamMemberCard = ({
  name,
  job,
  image,
  selected,
  onClick,
  imagePosition,
  onMouseDown,
}) => {
  const TEXTS = {
    SEE_MORE: t('seeMore'),
  };
  let cardClasses = Style.cardContainer;
  if (selected) cardClasses += ' ' + Style.selected;

  return (
    <div className={cardClasses} onMouseDown={onMouseDown}>
      <div onClick={onClick} className={Style.teamMemberCard}>
        <Img
          src={image}
          style={{
            position: 'absolute',
            overflow: 'inherit',
          }}
          imgStyle={{
            objectPosition: imagePosition.join(' '),
          }}
          className={Style.image}
        />
        <div className={Style.cardContent}>
          <div className={Style.memberInfo}>
            <p className={Style.name}>{name}</p>
            <p className={Style.job}>{job}</p>
          </div>
          <div className={Style.cardCta}>
            <p className={Style.ctaLabel}>{TEXTS.SEE_MORE}</p>
            <span className={Style.ctaArrow}></span>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamMemberCard.propTypes = {
  name: P.string,
  job: P.string,
  image: P.string,
  selected: P.bool,
  onClick: P.func,
  imagePosition: P.array,
  onMouseDown: P.func,
};

export default TeamMemberCard;
