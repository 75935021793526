// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import Img from '@components/ImageWrapper';

const SectionAboutUs = ({ title, description, image }) => {
  const {
    section,
    bgImage,
    textContainer,
    sectionTitle,
    verticalLine,
    lineDot,
    sectionDescription,
  } = Style;

  return (
    <section className={section}>
      <Img
        src={image}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className={bgImage}
      />
      <div className={textContainer}>
        <p className={sectionTitle}>
          <span className={lineDot} />
          <span className={verticalLine} />
          {title}
        </p>
        <p
          className={sectionDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
    </section>
  );
};

export default SectionAboutUs;
