// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import ScrollButton from '../ScrollButton';
import Img from '@components/ImageWrapper';
import { translate as t } from '../../utils/translate';

const HeroAboutUs = ({ title, image }) => {
  const TEXTS = {
    LEARN_MORE: t('learnMore'),
  };

  return (
    <section className={Style.section}>
      <div className={[Style.innerContainer, 'container'].join(' ')}>
        <Img
          src={image}
          className={Style.bgImage}
          imgStyle={{
            objectFit: 'contain',
            maxHeight: '70vh',
            marginBottom: '0px',
          }}
        />
        <div>
          <p className={Style.titleText}>
            {title}
            <span className={Style.dot} />
            <span className={Style.verticalLine}></span>
          </p>
        </div>
      </div>
      <div className={Style.scrollButtonContainer}>
        <ScrollButton text={TEXTS.LEARN_MORE} refID="heroAboutUsAnchor" />
      </div>
    </section>
  );
};

export default HeroAboutUs;
