// TODO: CHECK USE
// TODO: REFACTORING
import React, { useRef } from 'react';
import Style from './style.module.css';
import Button from '../Button';

const ScrollButton = ({ text, iconSide, to = '', style, refID }) => {
  const scrollTo = to ? to : refID;

  return (
    <a href={`#${scrollTo}`} className={Style.link}>
      <Button uppercase text={text} iconSide={iconSide} style={style} />
      {!to ? (
        <div id={scrollTo} className={Style.scrollButtonAnchor}></div>
      ) : (
        <div></div>
      )}
    </a>
  );
};

export default ScrollButton;
